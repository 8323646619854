
            import Lenis from 'lenis';
            const lenis = new Lenis();

            function raf(time) {
              lenis.raf(time);
              requestAnimationFrame(raf);
            }

            requestAnimationFrame(raf);
          
import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://db6a618dd6ff2368d6610f92d1b70ecb@o4506307840770048.ingest.us.sentry.io/4507776064684032",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()